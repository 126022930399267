div[tabindex='-1'] {
  display: block;
}

.landing-container {
  background-color: white;
  display: block !important;
}

.landing-header-desktop {
  @apply w-full hidden lg:flex justify-center items-center bg-white;

  &>div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 28px;

    &>.landing-header-left {
      display: flex;
      gap: 72px;

      &>a>svg {
        width: 141px;
        height: 43px;
      }

      &>ul {
        display: flex;
        align-items: center;
        gap: 20px;

        &>li {
          text-decoration: none;
          font-size: 16px;
          font-weight: 300;
          line-height: 21.82px;
          color: #1c1c1d;
        }
      }
    }

    &>.landing-header-right {
      display: flex;
      align-items: center;
      gap: 41px;

      &>a {
        font-size: 16px;
        font-weight: 400;
        line-height: 21.82px;
      }

      &>img {
        width: 23px;
        height: 24.8px;
        color: #1c1c1d;
      }

      &>button {
        padding: 12px 32px;
        font-size: 16px;
        font-weight: 500;
        line-height: 21.82px;
      }
    }
  }
}

.landing-header-mobile {
  @apply lg:hidden flex items-center justify-between bg-white px-7 py-4;

  &>a>svg {
    width: 141px;
    height: 43px;
  }
}

.landing-header-mobile-menu {
  @apply flex flex-col items-start justify-center py-6 px-[30px] gap-[14px];

  &>ul {
    @apply flex flex-col gap-[10px] w-[100%];

    &>li {
      @apply no-underline w-[100%] py-[10px] px-[30px] rounded-[6px] bg-[#f2f8fc] text-sm font-light leading-[21.82px] text-gray-900;
    }
  }
}


.landing-banner {
  @apply w-full h-[658px] flex flex-col items-center justify-center lg:px-0 px-[50px] md:px-[100px] gap-[30px];
  background-image: url('../images/landing/landing_banner.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &>.video-container {
    @apply w-[1033px] h-[564px];
  }

  &>div {
    max-width: 763px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    z-index: 3;
  }

  &>div>h1 {
    @apply md:text-[56px] text-[36px] md:leading-[69.45px] leading-[38.45px] font-bold text-white text-center;
  }

  &>div>p {
    @apply md:text-[16px] text-[14px] md:leading-[24px] leading-[18px] font-[400] text-white text-center;
  }
}

.landing-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
  background: rgba(3, 23, 38, 0.7);
  pointer-events: none;
  /* Ensure the overlay does not interfere with any interactions */
  z-index: 1;
}

// ! Landing About Section
.landing-about {
  @apply w-full h-[100%] lg:h-[726px] flex items-center justify-center px-[50px] xl:px-0 py-[60px] xl:py-0;

  &>div {
    @apply max-w-[1236px] flex content-center justify-center lg:justify-between flex-col lg:flex-row gap-[24px];

    &>img {
      @apply w-[100%] h-[100%] xl:w-[547px] xl:h-[541px];
    }

    &>div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;

      &>p {
        color: #2f840f !important;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.8px;
      }

      &>div {
        display: flex;
        flex-direction: column;
        max-width: 502px;
        gap: 24px;

        &>h3 {
          color: #1c1c1d !important;
          font-size: 32px;
          font-weight: 700;
          line-height: 44px;
          letter-spacing: -0.9px;
        }

        &>p {
          color: #585b63 !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.1px;
        }
      }
    }
  }
}

// ! Landing our Mission
.landing-mission {
  @apply w-full h-[100%] lg:h-[806px] flex flex-col items-center justify-center;
  background: linear-gradient(to right, #f2f8fc 0%, #f2fcf3 100%);

  &>div {
    @apply max-w-[927px] w-[100%] flex flex-col items-center justify-center gap-[64px] lg:px-0 px-[20px] sm:px-[50px] lg:py-0 py-[60px];

    &>div {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &>p {
        color: #2f840f !important;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 1.8px;
        text-align: center;
      }

      &>h4 {
        @apply text-[24px] md:text-[36px] font-medium leading-[30.65px] md:leading-[44.65px] text-center text-black;
      }
    }
  }
}

// Landing Volunteer
.landing-volunteer {
  @apply flex md:flex-row flex-col;

  &>div {
    @apply w-[100%] md:w-[50%];
  }

  &>.volunteer-left-section {
    &>img {
      height: 100%;
      width: 100%;
    }
  }

  &>.volunteer-right-section {
    @apply flex items-center justify-start p-[30px] xl:pl-[80px] xl:pr-0;
    background-color: #042034 !important;

    &>div {
      @apply max-w-[535px] flex flex-col lg:gap-[40px] gap-[12px];

      &>div {
        display: flex;
        flex-direction: column;
        gap: 23px;

        &>h3 {
          font-size: 40px;
          font-weight: 600;
          line-height: 49.61px;
          color: #ffffff;
        }

        &>p {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          color: #ffffff;
        }
      }
    }
  }
}

// landing-volunteer-stats
.landing-volunteer-stats {
  @apply w-full h-[100%] sm:h-[256px] flex items-center flex-col sm:flex-row bg-[#0967af];

  &>.volunteer-stat {
    @apply w-full flex items-center justify-center flex-col gap-[19px] sm:h-auto h-[256px];

    &>h3 {
      font-size: 60px;
      font-weight: 200;
      line-height: 50px;
      text-align: center;
      color: #ffff;
    }

    &>p {
      font-size: 18px;
      font-weight: 500;
      line-height: 19.8px;
      text-align: center;
      color: #ffff;
    }
  }
}

// Landing Donation
.landing-donation {
  @apply flex lg:flex-row flex-col items-center justify-center mb-[10%] mt-[10%] gap-[30px] px-[20px] xl:p-0;

  &>div {
    @apply lg:w-[50%] w-[100%] flex items-center justify-center;
  }

  &>.donation-left-section {
    @apply lg:w-[50%] w-[100%] flex justify-center lg:justify-end;

    &>img {
      width: auto;
      height: 100%;
      border-radius: 12px 0px 0px 0px;
      // margin-left: 16%;
    }
  }

  &>.donation-right-section {
    @apply flex items-center justify-center lg:justify-start;
    // padding-left: 40px;

    &>div {
      @apply max-w-[535px] w-[100%] flex flex-col items-center justify-center gap-[40px];

      &>div {
        display: flex;
        flex-direction: column;
        gap: 23px;

        &>h3 {
          @apply lg:text-[40px] text-[28px] font-bold lg:leading-[44px] leading-[30px] tracking-[-0.9px] text-[#1C1C1D] text-left;
        }

        &>p {
          @apply font-normal lg:text-[26px] text-[18px] lg:leading-[26px] leading-[22px] tracking-[0.1px] text-[#585B63] text-left;
        }
      }
    }
  }
}

.banner-donation-button {
  border-radius: 12px;
  background-color: #0967AF;
  width: 535px;
  height: 59px;
  padding: 18px 0px 17px 0px;
}

.donation-form-button {
  display: flex;
  background-color: #EAF4FA;
  max-width: 526px;
  width: 100%;
  color: #000000;
  height: 59px;
  padding: 6px;
  border-radius: 49px;

  &>div {
    width: 100%;
    max-width: 277px;
    height: 47px;
    top: 6px;
    left: 6px;
    padding: 12px 0px 11px 0px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.10000000149011612px;
    cursor: pointer;
  }

  &>.donation-once {
    background-color: #fff;
    border-radius: 87px;
  }
}

.donation-amount-selection-row-1 {
  @apply flex gap-[15px] w-[100%] sm:w-auto flex-wrap text-black;

  &>input[type="radio"] {
    display: none;
  }

  &>input[type="radio"]:checked+label,
  .Checked+label {
    background: #f2f8fc;
    border: 1px solid #0967AF;
    font-weight: bold;
    text-align: center;
  }

  &>label {
    cursor: pointer;
    width: 164px;
    height: 59px;
    padding: 18px 62px 17px 63px;
    border-radius: 8px;
    border: 1px solid #CBD3D8;

  }

  &>label:hover {
    background: #f2f8fc;
  }
}

.donation-amount-selection-row-2 {
  display: flex;
  gap: 15px;

  &>input[type="radio"] {
    display: none;
  }

  &>input[type="radio"]:checked+label,
  .Checked+label {
    background: #f2f8fc;
    font-weight: bold;
    border: 1px solid #0967AF;
  }

  &>label {
    cursor: pointer;
    width: 164px;
    height: 59px;
    padding: 18px 62px 17px 63px;
    border-radius: 8px;
    border: 1px solid #CBD3D8;

  }

  &>label:hover {
    background: #f2f8fc;
  }

  &>input[type='text'] {
    width: 164px;
    height: 59px;
    border-radius: 8px;
    border: 1px solid #CBD3D8;
    text-align: center;
  }
}

// LandingCommunity Video
.landing-community-video {
  @apply w-full h-[100%] lg:h-[1000px] bg-white flex justify-center items-center px-[30px] py-[60px] xl:p-0;

  &>div {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &>div>.landing-community-video-header {
    &>h3 {
      color: #000000 !important;
      font-size: 40px;
      font-weight: 600;
      line-height: 56px;
      text-align: center;
    }

    &>p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #42363a !important;
    }
  }

  &>div>.landing-community-video-player {
    @apply max-w-[1098px] h-[100%] xl:h-[631px] flex items-center justify-center rounded-[16px] bg-[#dff5d7] p-[24px];
    background-image: url('../images/landing/community-video-background.png');

    &>.video-container {
      @apply w-[100%] max-w-[1033px] h-[100%];

      &>.thumbnail-container {
        width: 100%;
        height: 100%;
        border-radius: 15px;
        position: relative;
        cursor: pointer;

        &>img {
          width: 100%;
          height: 100%;
        }

        &>.play-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(255, 255, 255, 0.7);
          border-radius: 50%;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &>.play-icon {
          width: 30px;
          height: 30px;
          color: #007bff;
          /* Customize play button color */
        }
      }

      &>video {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
  }

  &>div>.landing-community-video-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
}

.list-icon {
  width: 51px;
  height: 51px;
  border-radius: 100%;
}

.list-icon-active {
  width: 87px;
  height: 87px;
  border-radius: 100%;
  border: 2px solid #0967af;
  padding: 2px;
}

// ! Landing Events
.landing-events {
  @apply w-full xl:h-[806px] h-[100%] px-[14px] sm:px-[100px] py-[50px] xl:p-0 flex flex-col items-center justify-center gap-[40px];

  &>h1 {
    @apply text-black sm:text-[40px] text-[24px] font-semibold sm:leading-[56px] leading-[36px];
  }

  &>div {
    @apply flex flex-wrap items-center justify-center lg:justify-start gap-[32px];
  }
}

.event-card {
  max-width: 378px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>img {
    width: 378px;
    height: 287px;
    border-radius: 12px;
  }

  &>div {
    width: 300.98px;
    height: 138px;
    border-radius: 12px;
    border: 1px solid #ededed;
    box-shadow: 0 0 11.2px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: -40px;
    background-color: white;

    &>span {
      width: 126px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      border-radius: 4px;
      color: #ffffff !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 17.36px;
      background: #0967af;
      padding: 4px;
      margin-top: -24px;
    }

    &>h3 {
      color: #000000 !important;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      max-width: 246px;
    }

    &>a {
      color: #0967af !important;
      font-size: 15px;
      font-weight: 600;
      line-height: 18.5px;
    }
  }
}

// ! Landing Footer
.landing-footer {
  @apply w-full flex flex-col items-center justify-center bg-[#042034] pt-[100px] px-[30px] pb-[20px];

  &>hr {
    height: 0.1px;
    width: 100%;
    max-width: 1319px;
    background-color: #2d2c2c;
    border: none;
  }

  &>.landing-footer-section {
    @apply max-w-[1309px] w-full sm:h-[111px] h-[100%] flex sm:flex-row flex-col items-start sm:items-center justify-between gap-[16px];

    &>.landing-footer-logo {
      @apply flex flex-col gap-[6px] mb-[30px] sm:mb-[100px];

      &>h4 {
        font-size: 41.31px;
        font-weight: 700;
        line-height: 43.96px;
        text-align: start;
        color: #ffff;
      }

      &>p {
        font-size: 15px;
        font-weight: 400;
        line-height: 20.46px;
        color: #8baac0 !important;
      }
    }

    &>.landing-footer-address {
      @apply flex flex-col gap-[6px] mb-[30px] sm:mb-[100px];

      &>h3 {
        font-size: 19px;
        font-weight: 700;
        line-height: 19px;
        color: #ffff;
      }

      &>p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26.46px;
        color: #8baac0 !important;
      }
    }

    &>.landng-footer-menu {
      @apply w-full flex items-center justify-between flex-wrap gap-0 sm:gap-[16px];

      &>li {
        @apply w-[50%] sm:w-auto sm:mb-0 mb-[10px];
      }

      &>li>a {
        text-decoration: none;
        font-size: 18px;
        font-weight: 300;
        line-height: 24.55px;
        color: #8baac0 !important;
      }
    }

    &>.landing-footer-terms {
      @apply flex w-[100%] sm:w-auto sm:gap-[53px] gap-0 sm:text-[14px] text-[10px] sm:justify-start justify-between font-light sm:leading-[19.1px] leading-[12.1px] text-[#8baac0];
    }

    &>.landing-footer-icons {
      display: flex;
      gap: 5px;
    }
  }
}

#landing-footer-hr {
  background-color: #494747;
}

// Landing Feedback
.landing-feedback {
  @apply w-full lg:h-[681px] h-[100%] bg-[#f2f8fc];

  &>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.carousel {
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel-inner {
  width: fit-content;
}

.carousel-control-next-icon {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  border: 1px solid #0967af;
  color: #232323 !important;
}

.carousel-custom-item {
  @apply max-w-[772px] w-full h-[100%] lg:h-[681px] flex flex-col items-center justify-center sm:px-[100px] px-[60px] py-[50px] lg:p-0;

  &>.carousel-custom-item-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    &>h3 {
      @apply md:text-[40px] text-[32px] font-normal leading-[56px] text-center;
      color: #000000 !important;
    }

    &>p {
      @apply md:text-[16px] text-[14px] font-normal md:leading-[24px] leading-[18px] text-center;
      color: #42363a !important;

    }
  }

  &>.carousel-custom-item-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 36px;

    &>p {
      @apply lg:text-[24px] text-[16px] font-normal lg:leading-[36px] leading-[20px] text-center mt-[30px] text-[#232323];
    }

    &>div {
      display: flex;
      gap: 12px;
      width: fit-content;

      &>img {
        width: 42px;
        height: 42px;
      }

      &>div {
        &>h5 {
          color: #232323 !important;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }

        &>p {
          color: #232323 !important;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }
    }
  }
}

.img-style {
  width: 100%;
  height: fit-content;
}

// ! Marquee
.marquee-container {
  @apply max-w-[1226px] w-[100%] overflow-hidden;

}

.marquee {
  width: 1226px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  gap: 28px;
  animation: marquee 30s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

// ! Landing Common
.section-title {
  color: #2f840f !important;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 1.8px;
  text-align: start;
}

.banner-primary-button {
  border-radius: 12px;
  padding: 16px 24px;
}

.banner-secondary-button {
  border-radius: 12px;
  padding: 16px 24px;
  background-color: #38b922 !important;
}

.button-w-fit {
  width: fit-content;
}

.row-reverse {
  @apply flex-col-reverse md:flex-row-reverse;

  &>.volunteer-right-section {
    @apply p-[30px] xl:px-[80px];
  }
}