@font-face {
  font-family: mastodon-font-sans-serif;
  src:
    local('Nunito Sans Italic'),
    url('../fonts/nunito_sans/NunitoSans-Italic-Variable.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: mastodon-font-sans-serif;
  src:
    local('Nunito Sans Bold'),
    url('../fonts/nunito_sans/NunitoSans-VariableFont.ttf') format('truetype');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: mastodon-font-sans-serif;
  src:
    local('Nunito Sans Medium'),
    url('../fonts/nunito_sans/NunitoSans-VariableFont.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: mastodon-font-sans-serif;
  src:
    local('Nunito Sans'),
    url('../fonts/nunito_sans/NunitoSans-VariableFont.ttf') format('truetype');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
