@font-face {
  font-family: mastodon-font-monospace;
  src:
    local('Ibm Plex Mono'),
    url('../fonts/ibm-plex-mono/IBMPlexMono-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
