.dashboard__counters {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  margin-bottom: 20px;

  & > div {
    box-sizing: border-box;
    flex: 0 0 33.333%;
    padding: 0 5px;
    margin-bottom: 10px;

    & > div,
    & > a {
      padding: 20px;
      background: lighten($ui-base-color, 4%);
      border-radius: 4px;
      box-sizing: border-box;
      height: 100%;
    }

    & > a {
      text-decoration: none;
      color: inherit;
      display: block;

      &:hover,
      &:focus,
      &:active {
        background: lighten($ui-base-color, 8%);
      }
    }
  }

  &__num,
  &__text {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    color: $primary-text-color;
    margin-bottom: 20px;
    line-height: 30px;
  }

  &__text {
    font-size: 18px;
  }

  &__label {
    font-size: 14px;
    color: $darker-text-color;
    text-align: center;
    font-weight: 500;
  }
}

.dashboard {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 10px;

  @media screen and (width <=1350px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  &__item {
    &--span-double-column {
      grid-column: span 2;
    }

    &--span-double-row {
      grid-row: span 2;
    }

    h4 {
      padding-top: 20px;
    }
  }

  &__quick-access {
    display: flex;
    align-items: baseline;
    border-radius: 4px;
    background: $ui-button-background-color;
    color: $primary-text-color;
    transition: all 100ms ease-in;
    font-size: 14px;
    padding: 0 16px;
    line-height: 36px;
    height: 36px;
    text-decoration: none;
    margin-bottom: 4px;

    &:active,
    &:focus,
    &:hover {
      background-color: $ui-button-focus-background-color;
      transition: all 200ms ease-out;
    }

    &.positive {
      background: lighten($ui-base-color, 4%);
      color: $valid-value-color;
    }

    &.negative {
      background: lighten($ui-base-color, 4%);
      color: $error-value-color;
    }

    span {
      flex: 1 1 auto;
    }

    .fa {
      flex: 0 0 auto;
    }

    strong {
      font-weight: 700;
    }
  }
}

// ! Updated Dashboard Style

// ? Dashboard Header Styles
.header-container {
  @apply w-[100%] hidden md:flex items-center justify-center h-[64px] px-[30px] bg-white border border-[#DCE2E4];

  & > div {
    @apply max-w-[1300px] w-[100%] flex justify-between items-center;

    & > .header-logo-section {
      @apply flex items-center gap-[20px];

      & > a {
        @apply w-[153px] h-[47px] flex items-center;
      }
    }

    & > .header-profile-section {
      @apply flex items-center gap-[32px];
    }
  }
}

.searchbar {
  @apply w-[363px] max-w-[363px] h-[40px] flex items-center bg-[#EEF0F1] rounded-[12px] px-[10px] gap-[10px];

  & > img {
    @apply w-[20px] h-[20px];
  }

  & > input {
    @apply w-[100%] h-[100%] outline-none bg-[#EEF0F1];
  }
}

.header-user-info {
  @apply flex gap-[12px];
}

.dashboard-layout-container {
  @apply w-[100%] overflow-y-auto flex items-start justify-center bg-[#F4F5F6];
  height: calc(100vh - 76px);
  gap: 8px;

  & > div {
    @apply h-auto max-w-[1300px] w-[100%] flex justify-center sm:px-[20px] px-[10px] py-[20px] gap-[8px];
  }
}

// ! User Info
.header-user-info {
  @apply flex gap-[12px];

  & > img {
    @apply h-9 w-9 rounded-full object-cover;
  }

  & > div {
    & > h3 {
      @apply text-black;
    }

    & > p {
      @apply text-[#8A8A8A];
    }
  }
}

.post-user-info {
  @apply flex gap-[12px];

  & > img {
    @apply h-[41px] w-[41px] rounded-full object-cover;
  }

  & > div {
    @apply flex flex-col justify-center gap-[4px];

    & > h3 {
      @apply text-black text-[16px] font-[500] leading-[15px];
    }

    & > p {
      @apply text-[#8A8A8A] text-[14px] font-[400] leading-[15px];
    }
  }
}

// ! Sidebar

.dashboard-sidebar {
  @apply bg-white border border-[#DCE2E4] rounded-[12px] min-w-[257px] w-[257px] h-[100%] pt-[24px] px-[10px] hidden md:block;
  min-height: calc(100vh - 104px);

  & > ul {
    @apply w-[100%];

    // &>.dashboard_menu_item,
    // &>.dashboard_menu_dropdown {
    //   @apply flex items-center gap-[12px] py-[16px] ps-[20px] pe-[8px] rounded-[12px] text-[#585B63] text-[14px] leading-[15px] font-[400] no-underline hover:bg-[#eff4f7];

    //   &>a {
    //     @apply no-underline text-[#585B63] w-[100%] flex items-center gap-[12px];
    //   }
    // }

    // &>.dashboard_menu_dropdown_active {}
  }
}

.dashboard_menu_item_active {
  @apply bg-[#eff4f7] text-[#0967AF];

  & > a {
    @apply no-underline text-[#0967AF];
  }
}
.dashboard_menu_item_not_active {
  @apply text-[#585B63];

  & > a {
    @apply no-underline text-[#585B63];
  }
}

.dashboard_menu_item {
  @apply flex items-center justify-between gap-[12px] py-[16px] ps-[20px] pe-[8px] rounded-[12px]  text-[14px] leading-[15px] font-[400] no-underline hover:bg-[#eff4f7];

  & > a {
    @apply no-underline  w-[100%] flex items-center gap-[12px];
  }
}

.dashboard_menu_dropdown {
  @apply flex flex-col items-center justify-between gap-[12px] py-[16px] ps-[20px] pe-[8px] rounded-[12px] text-[#585B63] text-[14px] leading-[15px] font-[400] no-underline hover:bg-[#eff4f7];

  & > div {
    @apply w-[100%] flex items-center justify-between;

    & > span {
      @apply flex items-center gap-[12px];
    }
  }
}

.expand-dropdown {
  @apply h-[100%] w-[100%] scale-y-100 origin-top pb-[20px];
}

.collapse-dropdown {
  @apply h-[0px] w-[100%] scale-y-0 origin-top overflow-hidden;
}

// ! New Post
.new-post-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #dce2e4;
  border-radius: 12px;
  width: 100%;
  height: 140px;
  background-color: #fff;
  gap: 20px;

  & > .new-post-upper {
    @apply flex sm:gap-[16px] gap-[10px] px-[20px] pt-[20px];

    & > .new-post-inputs {
      @apply w-[100%] h-[41px] flex items-center bg-[#EEF0F1] px-[5px] py-[5px] gap-[10px] rounded-[12px];

      & > img {
        @apply w-[20px] h-[20px];
      }

      & > .new-post-text {
        @apply w-[100%] h-[100%] outline-none text-[#6D7376] bg-[#EEF0F1] pe-[6px];
      }

      & > .new-post-drop-down {
        @apply bg-white w-[100px] rounded-[8px] px-[12px] py-[8px] text-black;
      }
    }
  }

  & > .new-post-icons {
    @apply flex gap-[10px] sm:gap-[20px] ml-0 sm:ml-[71px] px-[20px] sm:px-0;
    & > .image-icon {
      width: 100%;
      margin-top: 21px;
      cursor: pointer;
    }
    & > .image-upload {
      display: flex;
      align-items: center;
      // &> input {
      //   display: none;
      // }
      & > .icon-lable {
        width: 100% !important;
        display: flex !important;
        // width: 24px;
        // height: 24px;
        gap: 30px;
      }
      
      & > input {
        display: none;
      }
    }
    & > .translate-button {
      width: 104px;
      height: 32px;
      padding: 4px 12px 4px 12px;
      display: flex;
      flex-direction: row;
      gap: 8px;
      border: 1px solid #e3e3e3;
      border-radius: 37px;
    }

    & > .seperator {
      border-left: 1px solid #e3e3e3;
      height: 22.5px;
      margin-top: 20px;
    }

    & > .translate-button {
      margin-bottom: 20px;
    }
    & > .post-submit-div {
      display: flex;
      &> .post-submit {
        @apply w-[120px] h-[40px] bg-[#0967AF];
        position: relative;
        left: 43px;
        top: 5px;
      }
      &> .update-submit {
        @apply w-[120px] h-[40px] bg-[#23bb56];
        position: relative;
        left: 43px;
        top: 5px;
      }
      & > p {
        color: #000;
        position: relative;
        left: 21px;
        top: 16px;
        font-weight: 600;
      }
    }
  }
}

.post-main-container {
  background-color: #747474;
  border: 1px solid yellow;
}

// ! Trending Container
.trending-container {
  width: 297px;
  height: 411px;
  padding: 23px 10px 23px 18px;
  border-radius: 12px;
  border: 1px solid #dce2e4;
  justify-content: space-between;
  background-color: #fff;

  & > .trending-content > .trending-heading {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    color: #000;
    gap: 75px;
    white-space: nowrap;
    margin-bottom: 32px;

    & > a {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      cursor: pointer;
      text-decoration: none;
    }
  }

  & > .trending-content > .trending-posts {
    & > .trending-title {
      display: flex;
      flex-direction: row;
      gap: 135px;
      font-size: 15px;
      font-weight: 600;
      line-height: 15px;
      text-align: left;
      cursor: pointer;
    }

    & > .trending-count {
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      text-align: left;
      color: #747474;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }
}

// ! Posts container
.posts-container {
  @apply w-[100%] flex flex-col gap-[8px];
}

.post {
  @apply w-[100%] flex flex-col items-center p-[20px] border border-[#DCE2E4] rounded-[12px] bg-white text-black;
}

.post-author {
  @apply w-[100%] flex justify-between;

  & > p {
    @apply text-[#747474] flex items-center text-[15px] font-[400] leading-[22px];
  }
}

.post-detail {
  @apply w-[100%] flex flex-col gap-[12px] mt-[20px] px-[0px] lg:px-[56px];

  & > h3 {
    @apply text-[#1C1C1D] text-[15px] font-[500] leading-[18px];
  }

  & > p {
    @apply text-[#444242] text-[15px] font-[400] leading-[22px];
  }
}

.post-tags {
  @apply text-[#0967AF] text-[15px] font-[400] leading-[17px] flex items-center gap-[6px] flex-wrap;
}

.post-actions {
  @apply w-[100%] flex justify-between;
  position: relative;
  & > div {
    @apply flex gap-[16px] sm:gap-[32px];
    &>.post-modal {
      display: block;
      background: #fff;
      width: 88px;
      height: 63px;
      border: 1px solid #dce2e4;
      border-radius: 12px;
      position: absolute;
      right: -77px;
      top: 21px;
      display: flex;
      flex-direction: column;
      padding: 12px;
      &>.post-modal-a {
        display: inline-block;
        text-decoration: none;
        font-weight: 600;
        color: #000;
        margin: 0px 0px 5px 12px;
      }
    }
    &>.post-modal-hide {
      display: none;
      &>.post-modal-a-hide {
        display: none;
      }
    }
    & > p {
      @apply text-[#747474] text-[14px] font-[400] leading-[22px] flex items-center gap-[4px];
    }

    & > button {
      @apply text-[#747474] text-[14px] font-[400] leading-[22px] flex items-center gap-[4px];
    }
  }
}

// ! Layout Right Sidebar
.layout-right-sidebar {
  @apply hidden xl:flex flex-col gap-[8px];
}

// ! card-wrapper
.card-wrapper {
  @apply w-[297px] border border-[#DCE2E4] rounded-[12px] p-[20px] bg-white flex flex-col gap-[32px];

  & > .card-header {
    @apply flex items-center justify-between;

    & > h3 {
      @apply text-[20px] font-[600] leading-[15px] text-black;
    }

    & > a {
      @apply text-[12px] font-[400] leading-[15px] text-[#0967AF] no-underline;
    }
  }

  & > .card-item {
    @apply flex items-center justify-between;
  }
}

// ! trending-item

.trending-item {
  @apply flex justify-between items-center;

  & > div {
    @apply flex flex-col gap-[8px];

    & > h3 {
      @apply text-[15px] font-[600] leading-[15px] text-[#000000];
    }

    & > p {
      @apply text-[14px] font-[400] leading-[15px] text-[#747474];
    }
  }
}
